import * as React from "react"
import { Seo } from "../components/seo"
import Layout from '../components/layout'
import Sidebar from '../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"
import "../css/gallery.scss"
import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const onInit = () => {
	console.log('lightGallery has been initialized');
};

const HOOPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Home Ownership Opportunities</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<section>
						<p><strong>Self-Reliance</strong>: Through shelter, we empower. A decent place to live and an affordable mortgage help homeowners save more, invest in education, pursue opportunity, and have more financial stability.</p>
					</section>

					<section>
						<h3>Partner Families Must Meet Certain Criteria to Obtain a Habitat Home</h3>

						<div className="grid-1-2">
							<StaticImage src="../images/hoo-blob-1.png" alt="Blob 1" />
							<div>
								<h5>1. In need of better housing.</h5>
								<p>Potential homebuyers might be dealing with poorly built, unhealthy, damaged, or inadequate housing. Alternatively, you might have a rent or mortgage that you cannot afford, or may be living in a home that is inaccessible for your disabilities.</p>
							</div>
						</div>

						<div className="grid-1-2">
							<StaticImage src="../images/hoo-blob-2.png" alt="Blob 2" />
							<div>
								<h5>2. Willing to partner with Habitat.</h5>
								<p>Our homebuyers put in hundreds of hours of <strong>“sweat equity”</strong>, which means they help build their own home and the homes of others in the Habitat for Humanity house building program, or volunteer at a Habitat ReStore. These prerequisite hours may also include classes in personal finances, home maintenance, and other relevant topics to help ensure capability for the demands of owning a home.</p>
							</div>
						</div>
						
						<div className="grid-1-2">
							<StaticImage src="../images/hoo-blob-3.png" alt="Blob 3" />	
							<div>
								<h5>3. Able to pay an affordable mortgage.</h5>
								<p>In partnership with the USDA, Habitat for Humanity NW WI offers homebuyers an affordable mortgage. Those payments are then cycled back into the community to help build more affordable homes. Does your monthly gross income from all sources fall within the income limit range listed on the back of this page? If so, you may qualify. Contact us for more information. We look forward to hearing from you.</p>
							</div>
						</div>

					</section>

					<section>
						<h2>Interested in Becoming a Partner Family?</h2>
						<p>Complete this form: <a href="https://forms.gle/xpWMHevci5wmcRTR8" target="_blank">Habitat Partner Family Application</a> and we will contact you.</p>
						<p>Or email us at: <a href="mailto:families@habitatnorthwoods.org">families@habitatnorthwoods.org</a></p>
						<p className="d-flex">
							<a href="https://www.rd.usda.gov/sites/default/files/fact-sheet/508_RD_FS_RHS_SFH502Direct.pdf" target="_blank" className="button">Rural Home Loans</a>
							<a href="https://www.rd.usda.gov/sites/default/files/wi_direct_programsummary_incomelimits.pdf" target="_blank" className="button">USDA Direct Loan</a>
						</p>
						<div className="spacer"></div>
						<p className="d-flex">
							<StaticImage src="../images/equal-housing-logo.png" alt="Equal Housing Logo" />
							<StaticImage src="../images/every-door-image.png" alt="Every Door Open Up New Opportunity" />
						</p>
						<p><StaticImage src="../images/hoo-qr.png" alt="QR" /></p>
					</section>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default HOOPage

export const Head = () => (
	<Seo
        title="About | Habitat For Humanity Northwoods Wisconsin"
    />
)
